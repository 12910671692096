import currentLanguage from "./components/Language";
import HeaderSearch from "./components/HeaderSearch";
import "../../../node_modules/lazysizes/lazysizes";
import "../../../node_modules/lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "../../../node_modules/lazysizes/plugins/bgset/ls.bgset";
import "../../../node_modules/bootstrap/js/src/util";
import "../../../node_modules/bootstrap/js/src/dropdown";
import "../../../node_modules/bootstrap/js/src/collapse";
import "../../../node_modules/vanilla-cookieconsent/dist/cookieconsent";

import "../../../node_modules/swiper/src/swiper.scss";
// import "../../../node_modules/vanilla-cookieconsent/src/cookieconsent.css";

const lang = currentLanguage();
// window.mapsApiLoaded = false;

const headerSearch = new HeaderSearch();
headerSearch.init();

document.addEventListener("lazybeforeunveil", function (e) {
  let bg = e.target.getAttribute("data-bg");
  if (bg) e.target.style.backgroundImage = "url(" + bg + ")";

  let script = e.target.getAttribute("data-script");
  if (script) {
    if (!cc.allowedCategory("analytics")) {
      e.preventDefault();
    } else {
      return;
    }
  }
});

function runCookieMap(cookie) {
  if (cookie.categories.indexOf("analytics") > -1) {
    if (document.querySelector("[data-script]") !== null) {
      const scriptEl = document.querySelector("[data-script]");

      if (cc.allowedCategory("analytics")) lazySizes.loader.unveil(scriptEl);
    }

    if (document.querySelector('[data-type="yt"]')) {
      document.location.reload();
    }
  }
}

// cookie consent
window.cc = initCookieConsent();
cc.run({
  current_lang: lang,
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  gui_options: {
    consent_modal: {
      layout: "box", // box/cloud/bar
      position: "bottom left", // bottom/middle/top + left/right/center
      transition: "slide", // zoom/slide
      swap_buttons: false, // enable to invert buttons
    },
    settings_modal: {
      layout: "box", // box/bar
      // position: 'left',           // left/right
      transition: "slide", // zoom/slide
    },
  },
  onFirstAction: function (user_preferences, cookie) {},
  onAccept: function (cookie) {
    // allLocationsMap.init();
    runCookieMap(cookie);
  },
  onChange: function (cookie, changed_categories) {
    runCookieMap(cookie);
    // lazySizes.loader.checkElems; allLocationsMap.init();console.log('change')
  },
  languages: {
    sl: {
      consent_modal: {
        title: "Piškotki",
        description:
          'To spletno mesto uporablja piškotke. S piškotki zagotavljamo boljšo uporabniško izkušnjo in analizo uporabe. S klikom na »Sprejmem vse« dovoljujete vse namene obdelave lahko pa privolite po meri s klikom na <button type="button" data-cc="c-settings" class="cc-link">Nastavitve piškotkov</button>. Več o piškotkih lahko preberete <a href="/sl/piskotki/" class="cc-link">tukaj</a>.',
        primary_btn: {
          text: "Sprejmem vse",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Samo nujni",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Nastavitve piškotkov",
        save_settings_btn: "Shrani nastavitve",
        accept_all_btn: "Sprejmem vse",
        reject_all_btn: "Samo nujni",
        close_btn_label: "Zapri",
        // cookie_table_caption: 'Cookie list',
        cookie_table_headers: [
          { col1: "Naziv" },
          { col2: "Upravljalec" },
          { col3: "Veljavnost" },
          { col4: "Opis" },
        ],
        blocks: [
          {
            title: "Zakaj piškotki?",
            description:
              'Piškotke uporabljamo za izboljšanje uporabniške izkušnje in zagotavljanje delovanja spletne strani. Analitični in oglaševalski piškotki niso obvezni, vendar vam omogočajo boljšo uporabniško izkušnjo nam pa povratne informacije o zanimivih vsebinah ter dosegu oglaševalskih kampanij. Več si lahko preberete v našem <a href="/sl/pravno-obvestilo/" class="cc-link">pravnem obvestilu</a>.',
          },
          {
            title: "Nujno potrebni piškotki",
            description:
              "Te piškotki so nujno potrebni za delovanje spletne strani in se avtomatično namestijo ob obisku splene strani.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analitični piškotki",
            description:
              "Analitične piškotke uporabljamo za izboljševanje vaše uporabniške izkušnje, saj nam pomagajo razumeti kako obiskovalci uporabljate spletno stran.",
            toggle: {
              value: "analytics", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_ga", // match all cookies starting with "_ga"
                col2: "Google",
                col3: "2 leti",
                col4: "Piškotek, ki beleži unikaten ID uporabnika",
              },
              {
                col1: "_ga_228YE09G85",
                col2: "Google",
                col3: "2 leti",
                col4: "Piškotek, ki ohranja stanje seje",
              },
              {
                col1: "_gid",
                col2: "Google",
                col3: "1 dan",
                col4: "Piškotek, ki razlikuje med unikatnimi uporabniki",
              },
              {
                col1: "_gat",
                col2: "Google",
                col3: "1 minuta",
                col4: "Piškotek, ki uravnava število poslanih zahtevkov Google Analytics, ne vsebuje informacij o uporabniku",
              },
            ],
          },
          {
            title: "Oglaševalski piškotki",
            description:
              "Piškotki za napredne oglaševalske tehnike na posamezni spletni strani, zagotavljajo prikaz oglasov, ki kar se da ustrezajo interesom uporabnika, z njihovo pomočjo lahko analiziramo obisk na spletni strani ter merimo učinkovitost oglaševalske kampanje.",
            toggle: {
              value: "marketing", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_fbp", // match all cookies starting with "_ga"
                col2: "Meta Platforms Ireland Limited",
                col3: "3 mesece",
                col4: "Prikazovanje primernih oglasov na družbenem omrežju fb - Remarketing",
              },
            ],
          },
          {
            title: "Več informacij",
            description:
              'Za več informacij o uporabi piškotkov in obdelavi podatkov kliknite <a class="cc-link" href="/sl/piskotki/">tukaj</a>.',
          },
        ],
      },
    },
    hr: {
      consent_modal: {
        title: "Kolačići",
        description:
          'Ova web stranica koristi kolačiće za funkcioniranje stranice, prikupljanje statistike, bolje korisničko iskustvo i kolačiće koji se koriste za marketing prilagođen vašim interesima. Možete ih odabrati klikom na <button type="button" data-cc="c-settings" class="cc-link">Postavke kolačića</button>. Više o kolačićima preberite <a href="/en/cookies/" class="cc-link">ovdje</a>.',
        primary_btn: {
          text: "Prihvati kolačiće",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Samo potrebno",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Postavke kolačića",
        save_settings_btn: "Spremi postavke",
        accept_all_btn: "Prihvati kolačiće",
        reject_all_btn: "Odbaci sve",
        close_btn_label: "Zatvori",
        // cookie_table_caption: 'Cookie list',
        cookie_table_headers: [
          { col1: "Titula" },
          { col2: "Menadžer" },
          { col3: "Valjanost" },
          { col4: "Opis" },
        ],
        blocks: [
          {
            title: "Zašto kolačići?",
            description:
              'Kolačiće koristimo kako bismo poboljšali korisničko iskustvo i osigurali funkcionalnost web stranice. Analitički i reklamni kolačići nisu obavezni, ali vam omogućuju bolje korisničko iskustvo te nam daju povratne informacije o zanimljivom sadržaju i dosegu reklamnih kampanja. Više možete pročitati u našoj <a href="/en/legal-notice/" class="cc-link">pravnoj obavijesti</a>.',
          },
          {
            title: "Obavezni kolačići",
            description:
              "Ovi kolačići su neophodni za funkcioniranje web stranice i automatski se instaliraju kada posjetite web stranicu.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analitički kolačići",
            description:
              "Koristimo analitičke kolačiće kako bismo poboljšali vaše korisničko iskustvo jer nam pomažu razumjeti kako posjetitelji koriste web stranicu..",
            toggle: {
              value: "analytics", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_ga", // match all cookies starting with "_ga"
                col2: "Google",
                col3: "2 leti",
                col4: "Piškotek, ki beleži unikaten ID uporabnika",
              },
              {
                col1: "_ga_228YE09G85",
                col2: "Google",
                col3: "2 leti",
                col4: "Piškotek, ki ohranja stanje seje",
              },
              {
                col1: "_gid",
                col2: "Google",
                col3: "1 dan",
                col4: "Piškotek, ki razlikuje med unikatnimi uporabniki",
              },
              {
                col1: "_gat",
                col2: "Google",
                col3: "1 minuta",
                col4: "Piškotek, ki uravnava število poslanih zahtevkov Google Analytics, ne vsebuje informacij o uporabniku",
              },
            ],
          },
          {
            title: "Marketing kolačići",
            description:
              "Kolačići za napredne tehnike oglašavanja na pojedinačnim web stranicama osiguravaju prikazivanje oglasa koji u najvećoj mogućoj mjeri odgovaraju interesima korisnika, uz njihovu pomoć možemo analizirati posjećenost web stranice i mjeriti učinkovitost reklamne kampanje.",
            toggle: {
              value: "marketing", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_fbp", // match all cookies starting with "_ga"
                col2: "Meta Platforms Ireland Limited",
                col3: "3 mesece",
                col4: "Prikazovanje primernih oglasov na družbenem omrežju fb - Remarketing",
              },
            ],
          },
          {
            title: "Saznajte više",
            description:
              'Za više informacija o korištenju kolačića i obradi podataka kliknite <a class="cc-link" href="/en/cookies/">ovdje</a>.',
          },
        ],
      },
    },
    en: {
      consent_modal: {
        title: "Cookies",
        description:
          'This website uses cookies for the functioning of the website, collecting statistics, better user experience and cookies used for marketing adapted to your interests. You can select them with a click on <button type="button" data-cc="c-settings" class="cc-link">cookie settings</button>. You can read more about cookies <a href="/en/cookies/" class="cc-link">here</a>.',
        primary_btn: {
          text: "Accept all",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Only necessary",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie settings",
        save_settings_btn: "Save settings",
        accept_all_btn: "Accept all",
        reject_all_btn: "Only necessary",
        close_btn_label: "Close",
        // cookie_table_caption: 'Cookie list',
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Owner" },
          { col3: "Expiry" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "Why cookies?",
            description:
              'We use cookies to improve the user experience and ensure the functioning of the website. Analytical and advertising cookies are not mandatory, but they give you a better user experience and feedback on interesting content and the reach of advertising campaigns. You can read more in our <a href="/en/legal-notice/" class="cc-link">legal notice</a>.',
          },
          {
            title: "Strictly necessary cookies",
            description:
              "These cookies are essential for the functioning of the website and are automatically installed when you visit the web page.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analytics cookies",
            description:
              "These cookies collect information about how you use the website, which pages you visited and which links you clicked on.",
            toggle: {
              value: "analytics", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_ga", // match all cookies starting with "_ga"
                col2: "Google",
                col3: "2 years",
                col4: "Unique visitor ID cookie",
              },
              {
                col1: "_ga_228YE09G85",
                col2: "Google",
                col3: "2 years",
                col4: "Session cookie",
              },
              {
                col1: "_gid",
                col2: "Google",
                col3: "1 day",
                col4: "Store and count pageviews",
              },
              {
                col1: "_gat",
                col2: "Google",
                col3: "1 minute",
                col4: "Read and filter requests from bots",
              },
            ],
          },
          {
            title: "Targeting and Advertising cookies",
            description:
              "Cookies for advanced advertising techniques on a particular website, provide the display of ads that are as relevant to the user's interests as possible, with their help we can analyze the visit to the website and measure the effectiveness of the advertising campaign.",
            toggle: {
              value: "marketing", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_fbp", // match all cookies starting with "_ga"
                col2: "Meta Platforms Ireland Limited",
                col3: "3 months",
                col4: "Displaying of relevant ads on facebook - Remarketing",
              },
            ],
          },
          {
            title: "More info",
            description:
              'For more information on the use of cookies and data processing click <a class="cc-link" href="/en/cookies/">here</a>.',
          },
        ],
      },
    },
    de: {
      consent_modal: {
        title: "Cookies",
        description:
          'Diese Website verwendet Cookies für das Funktionieren der Website, das Sammeln von Statistiken, eine bessere Benutzererfahrung und Cookies, die für Marketingzwecke verwendet werden, die an Ihre Interessen angepasst sind. Sie können sie mit einem Klick auf <button type="button" data-cc="c-settings" class="cc-link">Cookie-Einstellungen</button>. Weitere Informationen finden Sie in unserem <a href="/de/impressum/" class="cc-link">Impressum</a>.',
        primary_btn: {
          text: "Ich bin einverstanden",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Nur notwendige",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie-Einstellungen",
        save_settings_btn: "Einstellungen speichern",
        accept_all_btn: "Ich bin einverstanden",
        reject_all_btn: "Nur notwendige",
        close_btn_label: "Schließen",
        // cookie_table_caption: 'Cookie list',
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Eigentümer" },
          { col3: "Ablauf" },
          { col4: "Beschreibung" },
        ],
        blocks: [
          {
            title: "Warum Cookies?",
            description:
              'Wir verwenden Cookies, um das Benutzererlebnis zu verbessern und die Funktionsfähigkeit der Website sicherzustellen. Analyse- und Werbe-Cookies sind nicht zwingend erforderlich, geben Ihnen aber ein besseres Nutzererlebnis und Feedback zu interessanten Inhalten und der Reichweite von Werbekampagnen. Weitere Informationen finden Sie in unserem <a href="/de/impressum/" class="cc-link">Impressum</a>.',
          },
          {
            title: "Unbedingt notwendige Cookies",
            description:
              "Diese Cookies sind für das Funktionieren der Website unerlässlich und werden automatisch installiert, wenn Sie die Website besuchen.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analyse-Cookies",
            description:
              "Diese Cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht haben und welche Links Sie angeklickt haben.",
            toggle: {
              value: "analytics", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_ga", // match all cookies starting with "_ga"
                col2: "Google",
                col3: "2 Jahre",
                col4: "Cookie für die eindeutige Besucher-ID",
              },
              {
                col1: "_ga_228YE09G85",
                col2: "Google",
                col3: "2 Jahre",
                col4: "Sitzungscookie",
              },
              {
                col1: "_gid",
                col2: "Google",
                col3: "1 Tag",
                col4: "Speichern und Zählen von Seitenaufrufen",
              },
              {
                col1: "_gat",
                col2: "Google",
                col3: "1 Minute",
                col4: "Lesen und Filtern von Anfragen von Bots",
              },
            ],
          },
          {
            title: "Targeting- und Werbe-Cookies",
            description:
              "Cookies für fortschrittliche Werbetechniken auf einer bestimmten Website bieten die Anzeige von Anzeigen, die für die Interessen des Benutzers so relevant wie möglich sind, mit ihrer Hilfe können wir den Besuch der Website analysieren und die Wirksamkeit der Werbekampagne messen.",
            toggle: {
              value: "marketing", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "_fbp", // match all cookies starting with "_ga"
                col2: "Meta Platforms Ireland Limited",
                col3: "3 Monate",
                col4: "Schaltung relevanter Werbeanzeigen auf Facebook - Remarketing",
              },
            ],
          },
          {
            title: "Mehr Info",
            description:
              'Weitere Informationen zur Verwendung von Cookies und zur Datenverarbeitung finden Sie <a class="cc-link" href="/de/impressum/">hier</a>.',
          },
        ],
      },
    },
  },
});
