export default class HeaderSearch {
    constructor(options) {
        const _defaults = {

        }
        this.defaults = Object.assign({}, _defaults, options);
    }


    init() {
        $('.js-search-trigger').on('click', function () {
            if ($('.js-search-wrapper').hasClass('is-active')) {
                $('.js-search-wrapper').removeClass('is-active');
            } else {
                $('.js-search-wrapper').addClass('is-active');
            }

        })

        $('body').on('click', function () {
            if (!$(event.target).closest('.js-search-wrapper').length) {
                $('.js-search-wrapper').removeClass('is-active');
            }
        })
    }

}